import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Appbar from '../../../components/Appbar';
import Container from '@mui/material/Container';
import TextSection from '../../../components/TextSection';
import Footer from '../../../components/Footer';


export default function LandingPage() {
  const theme = useTheme();
  return (
    <Box display='flex' flexDirection='column' sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar />
        <Container maxWidth="md" sx={{ mt: 2, mb: 6 }}>
          <img src="/desk.jpeg" alt="blog1" style={{ width: '100%' }} />
        </Container>


        <Container maxWidth='md' sx={{ mb: 10 }}>
          <Typography variant="h4" align="center" color={theme.palette.grey['900']} sx={{ py: 4 }}>
            <b style={{ color: theme.palette.secondary.main }}>Unleashing Success:</b> The Simplex Media Group Growth Partner Plan
          </Typography>
          <TextSection blog>
            <Typography variant='body1'>
              Embarking on a journey of business growth can be a daunting task, but with the Simplex Media Group Growth Partner
              Plan, we've revolutionized the approach to ensuring your success. This innovative plan is not just a service; it's
              a commitment to becoming your dedicated growth partner. Let's delve into the transformative benefits that come with
              embracing this unparalleled strategy.
            </Typography>
          </TextSection>

          <TextSection smallTitle title="1. Holistic Marketing Integration" blog>
            <Typography variant='body1'>
              The Growth Partner Plan at Simplex Media Group is not bound by the limitations of a single
              marketing channel. We believe in the power of a comprehensive approach, utilizing all forms of
              marketing to amplify your brand presence and achieve tangible results. From the precision of
              digital marketing to the impact of traditional methods, we orchestrate a harmonious symphony of
              strategies tailored to your business needs. This integration ensures that your message resonates
              across diverse platforms, maximizing reach and engagement.

            </Typography>
          </TextSection>

          <TextSection smallTitle title="2. A Team Aligned with Your Business Vision" blog>
            <Typography variant='body1'>
              In the spirit of true partnership, the Growth Partner Plan involves the creation of a dedicated
              team around your business. This team is not just a collection of professionals; it's an extension
              of your vision and goals. We carefully curate a group of experts in Google Ads, programmatic
              advertising, SEO, and website optimization, forming a cohesive unit that works seamlessly to drive
              your business forward. This collaborative synergy ensures that every aspect of your marketing
              strategy is aligned with your overarching business objectives.

            </Typography>
          </TextSection>

          <TextSection smallTitle title="3. Results-Driven Payment Structure" blog>
            <Typography variant='body1'>
              One of the standout features of the Growth Partner Plan is our commitment to your success. We
              understand that the journey to growth is a shared responsibility, which is why we don't get paid
              until you see results. Our payment structure is results-driven, aligning our interests with yours.
              This means that our focus is not merely on delivering services but on delivering outcomes that
              positively impact your bottom line. Your success is our success, and we are dedicated to earning
              your trust through measurable and meaningful results.

            </Typography>
          </TextSection>


          <TextSection smallTitle title="4. Personalized In-Person Sales Support" blog>
            <Typography variant='body1'>
              In the era of digital dominance, we recognize the enduring value of in-person connections. The
              Growth Partner Plan extends beyond the virtual realm, offering personalized in-person sales
              support. Whether it's cultivating relationships with potential clients or closing deals
              face-to-face, our team is committed to providing the human touch that can make all the difference
              in fostering lasting business connections
            </Typography>
          </TextSection>

          <TextSection blog>
            <Typography variant='body1'>At Simplex Media Group, the Growth Partner Plan is more than a strategy; it's a commitment to your
              success story. We invite you to elevate your business to new heights, leveraging the power of
              holistic marketing, a dedicated team, results-driven payments, and personalized in-person sales
              support.

            </Typography>
            <Typography variant='body1'>
              Our journey is fueled by the success stories of the businesses we've had the privilege to partner with.
              Each click, each conversion, and every business milestone achieved by our clients is a testament to the
              dedication and hard work of the Simplex team.

            </Typography>
            <Typography variant='body1'>
              Ready to embark on a transformative journey with a growth partner who is as invested in your
              success as you are?
            </Typography>
            <Typography variant="h6" align='center'>
              Welcome to the Simplex Media Group Growth Partner Plan -- where your growth is
              our priority.
            </Typography>
          </TextSection>
        </Container>
      </Box>
      <Footer />
    </Box >
  );
}