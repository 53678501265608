import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useTheme from '@mui/material/styles/useTheme';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const testimonials = [
  {
    name: 'Mark D. Evernden',
    position: 'Top Producing Luxury Advisor - Century 21 Bamber',
    small: true,
    testimonial: 'SIMPLEX reached out to me regarding PR and social media services. After careful review with my team, I decided to go ahead with their top package. I\'m very pleased with the results. Today, I am published in over 300+ publications and have gained over 9,500 targeted followers on Instagram so far. I can confidently say that the SIMPLEX team is instrumental to my account growth and social media presence.'

  },
  {
    name: 'Chloe Shields',
    position: 'Influencer & Augmented Reality Designer',
    small: true,
    testimonial: 'SIMPLEX social media strategy and the solution had an almost immediate impact on my brand. They were able to increase the engagement in record time. This resulted in a high-value client win within the first three weeks of working with them. Meanwhile, getting booked for Instagram campaigns as an influencer! They understood what I need to monetize Instagram and built a custom campaign perfect for me that reached a large percentage of my potential client base with minimal effort on my part—leaving me free to focus on the core business.'
  },
  {
    name: 'Brooke Hvizdak',
    position: 'Broker Associate',
    small: true,
    testimonial: 'I have only worked with SIMPLEX for a short while but in that time I have seen amazing growth. I joined with SIMPLEX at the very beginning of creating my platform and they helped me see what was helpful for my business and the best ways for it to grow on social media. The customer service they have provided me is unlike anything I have ever experienced and I feel as though my every need was taken care of. 100% worth every penny and would recommend to anyone looking to grow their  local followers/leads!'
  },
  {
    name: 'Tom M',
    position: 'CEO & Restaurant Entrepreneur',
    small: false,
    testimonial: 'I first contacted SIMPLEX for a social media audit and was surprised at the number of improvements there were to be made. My branding is much better and  I\'m receiving more restaurant clientele than ever. 100% organic growth at a great rate. Thank you SIMPLEX!'
  },
  {
    name: 'Lumen Beltran',
    position: 'Actress',
    small: false,
    testimonial: 'SIMPLEX are awesome! They\'re professional, courteous, and highly responsive to all of my questions. Managing your own social media profiles can be overwhelming. It\'s great to have a team you can trust to handle your growth and  engagement. Highly recommend!'
  },
  {
    name: 'Damian B.',
    position: 'Psychologist',
    small: false,
    testimonial: 'I have had tough times finding someone trustworthy to help me manage my Instagram. Not only did I find this business trustworthy, but SIMPLEX gave me an intelligent, well organized, creative, and professional plan delivered on time at a good  rate by someone with skill and enthusiasm to boot!'
  }
];

function Testimonial({ name, position, testimonial, small }) {
  const theme = useTheme();
  return (
    <Box sx={{ px: 8 }}>
      <Typography variant="body1" align="center" color={theme.palette.grey['900']} sx={{ mt: 4, fontSize: small ? 18 : 22, fontWeight: 300 }}>
        <i>"{testimonial}"</i>
        <br />
        <br />
        <Typography variant="body2" align="center" color={theme.palette.secondary.main}>
          <b>{name}</b>, <i>{position}</i>
        </Typography>
      </Typography>

      <Box sx={{ height: '100px' }} />
    </Box>
  );
}

function Indicator({ onClickHandler, isSelected, index, label }) {
  const theme = useTheme();
  const boxTheme = {
    width: 8,
    height: 8,
    borderRadius: 20,
    background: theme.palette.grey['800'],
    display: 'inline-block',
    margin: '0 8px'
  };

  if (isSelected) {
    return (
      <Box
        sx={{ ...boxTheme, background: theme.palette.secondary.main }}
        aria-label={`Selected: ${label} ${index + 1}`}
        title={`Selected: ${label} ${index + 1}`}
      />
    );
  }
  return (
    <Box
      sx={boxTheme}
      onClick={onClickHandler}
      onKeyDown={onClickHandler}
      value={index}
      key={index}
      role="button"
      tabIndex={0}
      title={`${label} ${index + 1}`}
      aria-label={`${label} ${index + 1}`}
    />
  );
};

export default function Testimonials() {
  const theme = useTheme();
  return (
    <div>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        showStatus={false}
        enableTouch={false}
        swipeable={false}
        showThumbs={false}
        dynamicHeight={true}
        renderIndicator={(onClickHandler, isSelected, index, label) => (
          <Indicator key={index} onClickHandler={onClickHandler} isSelected={isSelected} index={index} label={label} />
        )}
        renderArrowPrev={(onClickHandler) => (
          <IconButton
            onClick={onClickHandler}
            sx={{
              color: theme.palette.common.white,
              background: theme.palette.grey['800'],
              position: 'absolute',
              top: 'calc(50% - 15px)',
              left: 0,
              zIndex: 5
            }}>
            <ArrowBackIcon />
          </IconButton>
        )}
        renderArrowNext={(onClickHandler) => (
          <IconButton onClick={onClickHandler}
            sx={{
              color: theme.palette.common.white,
              background: theme.palette.grey['800'],
              position: 'absolute',
              top: 'calc(50% - 15px)',
              right: 0,
              zIndex: 5
            }}>
            <ArrowForwardIcon />
          </IconButton>
        )}
      >
        {testimonials.map(testimonial => <Testimonial key={testimonial.name} {...testimonial} />)}
      </Carousel>
    </div>
  );
}