import * as React from 'react';

import Box from '@mui/material/Box';

import Pulse from 'react-reveal/Pulse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useTheme from '@mui/material/styles/useTheme';

import { Link } from "react-router-dom";

const ItemWrapper = ({ title, icon, bg }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        background: theme.palette.grey['200'],
        backgroundImage: `url('${bg}')`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: { xs: '400px', sm: '500px', md: '700px' }
      }}
    >
      <Box flexGrow={1} />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          my: 2,
          py: 2,
          background: theme.palette.grey['50'],
          borderTop: 1,
          borderBottom: 1,
          borderColor: theme.palette.grey['900'],
          borderWidth: 5
        }}
        color={theme.palette.grey['50']}
      >
        <img src={icon} alt={title} style={{ maxWidth: '50%', maxHeight: '80px' }} />
        <Typography variant='h5' align='center' color={theme.palette.grey['900']} sx={{ mx: 2, fontWeight: 300 }}>{title}</Typography>
      </Box>
    </Box>
  );
};

export default function ServicesGrid() {
  const theme = useTheme();

  const [index, setIndex] = React.useState(null);
  return (
    <Box>
      <Grid
        container
        spacing={4}
        justifyContent="space-evenly"
        alignItems="stretch"
        style={{
          transition: theme.transitions.create("all", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          })
        }}
      >
        <Grid item xs={12} sm={6} md={6} lg={6} onMouseEnter={(e) => setIndex(0)} onMouseLeave={(e) => setIndex(null)}>
          <Link to="/work/vw_express" style={{ textDecoration: 'none' }}>
            <Pulse when={index === 0}>
              <ItemWrapper title="VW Express" icon="vw.png" bg="vwi.jpg" />
            </Pulse>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} onMouseEnter={(e) => setIndex(1)} onMouseLeave={(e) => setIndex(null)} sx={{ mt: { sm : 0, md: 8 } }}>
          <Link to="/work/the_macron_boutique" style={{ textDecoration: 'none' }}>
            <Pulse when={index === 1}>
              <ItemWrapper title="The Macron Boutique" icon="/mb.avif" bg="/mbi.avif" />
            </Pulse>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}