
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import useTheme from '@mui/material/styles/useTheme';
import useWindowSize from '../../hooks/useWindowSize';

import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

export default function TextSections({ blog, title = "", children, nested, top, color, smallTitle = false }) {
  const theme = useTheme();
  const bgColor = color || theme.palette.common.white;

  const { width } = useWindowSize();
  const isLarge = width >= theme.breakpoints.values.sm;

  if (blog) {
    return (
      <Box sx={{ background: bgColor, my: 4 }}>
        {smallTitle ? (
          <Typography variant='body1' sx={{ fontSize: '18px', fontWeight: 600 }}>{title}</Typography>
        ) : (
          <Typography variant='h6'>{title.toUpperCase()}</Typography>
        )}
        <Stack spacing={4} sx={{ mt: 2 }}>
          {children}
        </Stack>
      </Box >
    )
  }

  if (nested) {
    return (
      <Box sx={{ background: bgColor, my: 2, mt: top ? 0 : 2 }}>
        <Stack>
          {smallTitle ? (
            <Typography variant='body1' sx={{ fontSize: '18px', fontWeight: 600 }}>{title}</Typography>
          ) : (
            <Typography variant='h6'>{title.toUpperCase()}</Typography>
          )}
          {children}
        </Stack>
      </Box >
    );
  }
  return (
    <Box sx={{ background: bgColor }}>
      <Container maxWidth="md" sx={{ py: 6 }}>
        <Grid spacing={2} container>
          <Grid item xs={12} sm={4}>
            {isLarge ? (
              <Slide left>
                <Typography variant='h5' sx={{ fontWeight: 600 }}>{title.toUpperCase()}</Typography>
              </Slide>
            ) : (
              <Fade>
                <Typography variant='h5' sx={{ fontWeight: 600 }}>{title.toUpperCase()}</Typography>
              </Fade>
            )}
          </Grid>
          <Grid item xs={12} sm={8} sx={{ pl: { xs: 0, sm: 4 } }}>
            {isLarge ? (
              <Slide right>
                {children}
              </Slide>
            ) : (
              <Fade>
                {children}
              </Fade>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box >
  )
}