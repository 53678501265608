import useTheme from '@mui/material/styles/useTheme';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';

import Appbar from '../Appbar';
import Footer from '../../../components/Footer';

const msgs = {
  CLIENT: 'Accept Client Request',
  REFUSED: 'Refuse And/Or Block',
  REQUEST: 'Reinstate Account Request',
};

export default function DashboardResetPassword({ user, setUser }) {
  const location = useLocation();
  const isAdmin = user.type === 'ADMIN';
  const client = location.state?.client ?? user;
  const newType = location.state?.newType ?? 'ERROR';
  const helloMessage = isAdmin && `For ${client.companyName} [${client.email}]`;

  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState('');
  const [changePasswordError, setChangePasswordError] = React.useState('');

  const handleAdminChangePassword = (e) => {
    setLoading(true);
    e.preventDefault();

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: user._id, clientId: client._id, newType })
    };

    fetch('https://wd-simplex-media-group-server.fly.dev/user/changeType', requestOptions)
      .then(async (res) => {
        return { status: res.status, json: await res.json() };
      })
      .then((data) => {
        if (data.status !== 200) {
          setChangePasswordError(data.json.message);
          setSuccess('');
        } else {
          setSuccess(`Account status successfully updated to ${newType}`);
          setChangePasswordError('');
        }
      })
      .catch((err) => {
        console.error(err);
        setChangePasswordError('Failed reset password. Please try again later.');
        setSuccess('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!isAdmin || client.type === 'ADMIN') {
    return <Typography variant="body1" align="center" sx={{ m: 4, fontWeight: '300' }}>Permission Denied</Typography>;
  }

  return (
    <Box display='flex' flexDirection='column' sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar user={user} setUser={setUser} />
        <Container maxWidth="md" sx={{ minHeight: '100vh' }}>
          <Container maxWidth="sm">
            <Typography variant="h6" mt={3} align='center' color={theme.palette.grey['900']} >{msgs[newType]}</Typography>
            <Typography variant="h6" fontWeight={200} align='center' color={theme.palette.grey['900']} >for {helloMessage}</Typography>
            <Card variant='outlined' sx={{ mt: 2, background: theme.palette.grey['100'], p: 1 }}>
              <CardContent>
                <Box>
                  <Button color="secondary" variant="contained" sx={{ width: '100%', textTransform: 'none', height: '45px' }} onClick={handleAdminChangePassword} disabled={loading || success}>
                    {!loading &&
                      <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                        Confirm
                      </Typography>
                    }
                    {loading && <CircularProgress sx={{ color: '#fff' }} />}
                  </Button>
                  <Button color="info" variant="contained" sx={{ width: '100%', mt: 2, textTransform: 'none', height: '45px' }} onClick={() => navigate('/dashboard/admin')}>
                    <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                      Return to Dashboard
                    </Typography>
                  </Button>
                </Box>
              </CardContent>
            </Card>
            <Typography variant="body1" align="center" sx={{ m: 2, fontWeight: '450', color: '#d12f2f' }}>{changePasswordError}</Typography>
            <Typography variant="body1" align="center" sx={{ m: 4, fontWeight: '300' }}>{success}</Typography>
          </Container>
          <Box my={4} />
        </Container>
      </Box >
      <Footer />
    </Box >
  );
}