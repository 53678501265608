import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Appbar from '../../../components/Appbar';
import Container from '@mui/material/Container';
import TextSection from '../../../components/TextSection';
import Footer from '../../../components/Footer';


export default function LandingPage() {
  const theme = useTheme();
  return (
    <Box display='flex' flexDirection='column' sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar />
        <Container maxWidth="md" sx={{ mt: 2, mb: 6 }}>
          <img src="/blog1.png" alt="blog1" style={{ width: '100%' }} />
        </Container>


        <Container maxWidth='md' sx={{ mb: 10 }}>
          <Typography variant="h4" align="center" color={theme.palette.grey['900']} sx={{ py: 4 }}>
            <b style={{ color: theme.palette.secondary.main }}>Unlocking Growth:</b> A Journey with Simplex Media
          </Typography>
          <TextSection blog>
            <Typography variant='body1'>
              Greetings to all the ambitious entrepreneurs and business enthusiasts out there! I am thrilled to
              share the story behind the inception of Simplex Media Group, a dynamic marketing agency born out
              of the passion for helping businesses thrive in the digital landscape.
            </Typography>
            <Typography variant='body1'>
              In the ever-evolving realm of online commerce, we recognized the need for a strategic and
              results-driven approach to digital marketing. With our official launch in 2022, Simplex Media
              embarked on a mission to empower small and medium-sized businesses with the tools they need to
              not just survive but thrive in the competitive digital space.
            </Typography>
            <Typography variant='body1'>
              At Simplex, we specialize in three key pillars that we believe form the foundation of a successful
              online presence: Google Ads Mastery, Programmatic Advertising, and SEO & Website Optimization.
              These aren't just services for us; they are the building blocks of growth for our clients.
            </Typography>
          </TextSection>

          <TextSection smallTitle title="Google Ads Mastery" blog>
            <Typography variant='body1'>
              We understand the power of Google Ads in putting your business in front of potential customers
              at the right moment. From crafting compelling ad copies to optimizing campaigns for maximum ROI,
              we bring a wealth of experience to the table. At Simplex, it's not just about clicks; it's about
              meaningful connections that drive conversions and fuel your business's ascent.
            </Typography>
          </TextSection>

          <TextSection smallTitle title="Programmatic Precision" blog>
            <Typography variant='body1'>
              In the age of data-driven marketing, programmatic advertising is our secret weapon. We leverage
              cutting-edge technology to target the right audience with the right message, ensuring that every
              ad impression counts. Our commitment to programmatic precision means your marketing budget is
              invested wisely, reaching the audiences that matter most to your business.
            </Typography>
          </TextSection>

          <TextSection smallTitle title="SEO & Website Optimization" blog>
            <Typography variant='body1'>
              Your online presence begins with a well-optimized website. Our SEO experts dive deep into the
              intricacies of search engine algorithms to ensure your business not only gets noticed but ranks
              high on the search results page. Simplex's approach to SEO goes beyond visibility; it's about
              creating a digital experience that captivates your audience and keeps them coming back for more.
            </Typography>
          </TextSection>

          <TextSection blog>
            <Typography variant='body1'>
              What sets Simplex Media Group apart is not just our expertise but our commitment to working with
              businesses of all sizes. We understand the challenges faced by startups and SMEs, and our tailored
              solutions are designed to make a significant impact without breaking the bank.
            </Typography>
            <Typography variant='body1'>
              Our journey is fueled by the success stories of the businesses we've had the privilege to partner with.
              Each click, each conversion, and every business milestone achieved by our clients is a testament to the
              dedication and hard work of the Simplex team.

            </Typography>
            <Typography variant='body1'>
              So, whether you're a budding entrepreneur looking to make your mark or an established business seeking a
              digital facelift, Simplex Media Group is here to be your strategic partner in growth.

            </Typography>
            <Typography variant='body1'>
              Join us in this exciting journey of unlocking the full potential of your business in the digital realm.
              Let's write the next chapter of success together!
            </Typography>

            <Typography variant='h6' align='center'>
              Welcome to Simplex Media Group. Let's grow beyond boundaries.
            </Typography>
          </TextSection>
        </Container>
      </Box >

      <Footer />
    </Box >
  );
}