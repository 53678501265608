import useTheme from '@mui/material/styles/useTheme';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import Appbar from '../Appbar';
import Footer from '../../../components/Footer';

const initForm = {
  prevPassword: '',
  password: '',
  passwordTwice: ''
};

export default function DashboardResetPassword({ user, setUser }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [form, setForm] = React.useState(initForm);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState('');
  const [changePasswordError, setChangePasswordError] = React.useState('');

  const setValue = (id) => (e) => setForm({ ...form, [id]: e.target.value });

  const isAdmin = user.type === 'ADMIN';
  const client = location.state?.client ?? user;
  const helloMessage = isAdmin && `For ${client.companyName} [${client.email}]`;

  const handleChangePassword = (e) => {
    setLoading(true);
    e.preventDefault();

    setErrors({});

    let newErrors = {};
    if (!form.prevPassword) newErrors.prevPassword = 'Please enter your new current password';

    if (!form.password) newErrors.password = 'Please enter your new password';
    if (!form.passwordTwice) newErrors.passwordTwice = 'Please re-enter your new password';

    if (form.password.length < 6) newErrors.password = 'Password must be at least 6 characters';
    else if (form.password && form.passwordTwice && form.password !== form.passwordTwice) newErrors.passwordTwice = 'Passwords do not match!';

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...form, clientId: client._id })
      };

      fetch('https://wd-simplex-media-group-server.fly.dev/user/resetPassword', requestOptions)
        .then(async (res) => {
          return { status: res.status, json: await res.json() };
        })
        .then((data) => {
          if (data.status !== 200 || data.json.message !== "OK") {
            setChangePasswordError(data.json.message);
            setSuccess('');
          } else {
            setSuccess('Password successfully updated.');
            setChangePasswordError('');
          }
        })
        .catch((err) => {
          console.error(err);
          setChangePasswordError('Failed change password. Please try again later.');
          setSuccess('');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleAdminChangePassword = (e) => {
    setLoading(true);
    e.preventDefault();

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: user._id, clientId: client._id })
    };

    fetch('https://wd-simplex-media-group-server.fly.dev/user/resetPassword', requestOptions)
      .then(async (res) => {
        return { status: res.status, json: await res.json() };
      })
      .then((data) => {
        if (data.status !== 200) {
          setChangePasswordError(data.json.message);
          setSuccess('');
        } else {
          setSuccess(`Password successfully updated. Temporary password: ${data.json.message}`);
          setChangePasswordError('');
        }
      })
      .catch((err) => {
        console.error(err);
        setChangePasswordError('Failed reset password. Please try again later.');
        setSuccess('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box display='flex' flexDirection='column' sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar user={user} setUser={setUser} />
        <Container maxWidth="md" sx={{ minHeight: '100vh' }}>
          <Container maxWidth="sm">
            <Typography variant="h6" mt={3} mb={2} align='center' color={theme.palette.grey['900']} >Reset Password {helloMessage}</Typography>
            <Card variant='outlined' sx={{ background: theme.palette.grey['100'], p: 1 }}>
              <CardContent>
                {isAdmin ? (
                  <Box>
                    <Button color="secondary" variant="contained" sx={{ width: '100%', textTransform: 'none', height: '45px' }} onClick={handleAdminChangePassword} disabled={loading}>
                      {!loading &&
                        <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                          Reset Password
                        </Typography>
                      }
                      {loading && <CircularProgress sx={{ color: '#fff' }} />}
                    </Button>
                    <Button color="info" variant="contained" sx={{ width: '100%', mt: 2, textTransform: 'none', height: '45px' }} onClick={() => navigate(-1)}>
                      <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                        Return to Dashboard
                      </Typography>
                    </Button>
                  </Box>
                ) : (
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                  >
                    <Stack spacing={3}>
                      <TextField
                        required
                        label="Previous Password"
                        type='password'
                        size='small'
                        color='info'
                        onChange={setValue('prevPassword')}
                        value={form.prevPassword}
                        error={errors.prevPassword}
                        helperText={errors.prevPassword}
                      />
                      <TextField
                        required
                        label="Password"
                        type='password'
                        size='small'
                        color='info'
                        onChange={setValue('password')}
                        value={form.password}
                        error={errors.password}
                        helperText={errors.password}
                      />
                      <TextField
                        required
                        label="Retype your password"
                        type='password'
                        size='small'
                        color='info'
                        onChange={setValue('passwordTwice')}
                        value={form.passwordTwice}
                        error={errors.passwordTwice}
                        helperText={errors.passwordTwice}
                      />
                      <Button color="secondary" variant="contained" sx={{ textTransform: 'none', height: '45px' }} onClick={handleChangePassword} disabled={loading}>
                        {!loading &&
                          <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                            Change Password
                          </Typography>
                        }
                        {loading && <CircularProgress sx={{ color: '#fff' }} />}
                      </Button>
                      <Button color="info" variant="contained" sx={{ textTransform: 'none', height: '45px' }} onClick={() => navigate(-1)}>
                        <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                          Return to Dashboard
                        </Typography>
                      </Button>
                    </Stack>
                  </Box>
                )}
              </CardContent>
            </Card>
            <Typography variant="body1" align="center" sx={{ m: 2, fontWeight: '450', color: '#d12f2f' }}>{changePasswordError}</Typography>
            <Typography variant="body1" align="center" sx={{ m: 4, fontWeight: '300' }}>{success}</Typography>
          </Container>
          <Box my={4} />
        </Container>
      </Box >
      <Footer />
    </Box >
  );
}