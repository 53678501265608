import useTheme from '@mui/material/styles/useTheme';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Appbar from '../../components/Appbar';
import WorkGrid from '../../components/WorkGrid';
import Footer from '../../components/Footer';


export default function WorkPage() {
  const theme = useTheme();
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Appbar />
      <Container maxWidth="md">
        <Typography variant="h3" align="center" color={theme.palette.grey['900']} sx={{ py: 4 }}>
          Our Work
        </Typography>
        <Box sx={{ mt: 4, mb: 12 }}>
          <WorkGrid />
        </Box>
        <Grid
          display='flex'
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={6} sm={3}>
            <img src={'/jewel.png'} alt={'jewel'} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={6} sm={3} sx={{ mr: { xs: 0, sm: 1.5 } }}>
            <img src={'/pyra.png'} alt={'pyramid'} style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={6} sm={3}>
            <img src={'/purbright.PNG'} alt={'pubright'} style={{ width: '90%', maxWidth: '170px' }} />
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </Box >
  );
}