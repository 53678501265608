import useTheme from '@mui/material/styles/useTheme';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import { Link } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';

export default function InvoiceLine({ invoice, outstanding, isAdmin, adminPage }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const invoiceId = invoice?.id;
  const totalDue = (((invoice?.amount_due) / 100).toFixed(2) ?? '----') + " " + (invoice?.currency?.toUpperCase() ?? "");
  const dueDate = invoice?.due_date ? new Date(invoice?.due_date * 1000).toDateString() : 'VOID';
  const status = invoice?.status?.toUpperCase() ?? 'VOID';
  const statusColor = status === 'OPEN'
    ? theme.palette.grey['900']
    : (status === 'PAID'
      ? theme.palette.success.main
      : theme.palette.secondary.main
    );
  const stripeInvoice = invoice?.hosted_invoice_url;
  const stripePdf = invoice?.invoice_pdf;
  const stripeModifyInvoice = `https://dashboard.stripe.com/invoices/${invoiceId}`
  const late = new Date(invoice?.due_date * 1000) < new Date();

  if (outstanding) {
    return (
      <Card variant='outlined' sx={{ mb: 2 }}>
        <CardContent>
          <Box sx={{ borderRadius: 5 }}>
            <Box display='flex' alignItems={'center'}>
              <Box flexGrow={1}>
                <Stack>
                  <Typography variant="body1" align="left" color={theme.palette.grey['900']}>
                    <b>Payee</b>: {!matches && <br />}Simplex Media Group
                  </Typography>
                  <Typography variant="body1" align="left" color={theme.palette.grey['900']}>
                    <b>Status</b>: {!matches && <br />} {status}
                  </Typography>
                  <Typography variant="body1" align="left" color={theme.palette.grey['900']}>
                    <b>Payment Due</b>: {!matches && <br />}{dueDate}
                  </Typography>
                </Stack>
              </Box>
              <Box>
                {late &&
                  <Typography variant="body2" align="center" color={theme.palette.secondary.main} fontSize={14} fontWeight='bold'>
                    LATE
                  </Typography>
                }
                <Typography variant="body1" align="center" color={theme.palette.grey['900']} fontSize={22}>
                  ${totalDue}
                </Typography>
              </Box>
            </Box>

            <Box my={2} />
            <Divider />
            <Box my={2} />
            {isAdmin ? (
              <Box display='flex' alignItems={'center'} justifyContent='space-between'>
                <Box flexGrow={1}>
                  <Link to={stripePdf} target="_blank" style={{ textDecoration: 'none', width: '100%' }}>
                    <Button variant="outlined" color='info' sx={{ p: 1, width: '100%' }}>
                      <Typography color={theme.palette.grey['900']}>
                        Download Invoice
                      </Typography>
                    </Button>
                  </Link>
                </Box>
                <Box mx={2} />
                <Box flexGrow={1}>
                  <Link to={stripeModifyInvoice} target="_blank" style={{ textDecoration: 'none', width: '100%' }}>
                    <Button variant="contained" color='secondary' sx={{ p: 1, width: '100%' }}>
                      <Typography color={theme.palette.grey['50']}>
                        Modify Invoice
                      </Typography>
                    </Button>
                  </Link>
                </Box>
              </Box>

            ) : (
              <Box display='flex' alignItems={'center'} justifyContent='space-between'>
                <Box flexGrow={1}>
                  <Link to={stripePdf} target="_blank" style={{ textDecoration: 'none' }}>
                    <Button variant="outlined" color='info' sx={{ p: 1, width: '100%' }}>
                      <Typography color={theme.palette.grey['900']}>
                        View Invoice
                      </Typography>
                    </Button>
                  </Link>
                </Box>
                <Box mx={2} />
                <Box flexGrow={1}>
                  <Link to={stripeInvoice} target="_blank" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color={isAdmin ? 'secondary' : 'success'} sx={{ p: 1, width: '100%' }}>
                      <Typography color={theme.palette.grey['50']}>
                        Pay Invoice
                      </Typography>
                    </Button>
                  </Link>
                </Box>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card >
    );
  }

  return (
    <Card variant='outlined' sx={{ my: 1 }}>
      <CardContent>
        <Stack>
          <Grid container alignItems='center' justifyContent='center'>
            <Grid item xs={12} sm={9}>
              <Grid container alignItems='center' justifyContent='center'>
                <Grid item xs={4}><Typography variant="body1" align="left" fontWeight='bold' color={theme.palette.grey['900']}>Date</Typography></Grid>
                <Grid item xs={4}><Typography variant="body1" align="center" fontWeight='bold' color={theme.palette.grey['900']}>Status</Typography></Grid>
                <Grid item xs={4}><Typography variant="body1" align="center" fontWeight='bold' color={theme.palette.grey['900']}>Total</Typography></Grid>
                <Grid item xs={4}><Typography variant="body1" align="left" fontSize={14} color={theme.palette.grey['900']}>{dueDate}</Typography></Grid>
                <Grid item xs={4}><Typography variant="body1" align="center" fontSize={14} fontWeight='bold' color={statusColor}>{status}</Typography></Grid>
                <Grid item xs={4}><Typography variant="body1" align="center" fontSize={14} color={theme.palette.grey['900']}>${totalDue}</Typography></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' alignItems={'center'} justifyContent='space-between'>
                <Box flexGrow={1}>
                  <Link to={stripeInvoice} target="_blank" style={{ textDecoration: 'none' }}>
                    <Button variant="outlined" color='info' size='small' sx={{ my: { xs: 2 }, width: '100%' }}>
                      View Invoice/Receipt
                    </Button>
                  </Link>
                  {adminPage &&
                    <Link to={stripeModifyInvoice} target="_blank" style={{ textDecoration: 'none', width: '100%' }}>
                      <Button variant="contained" color='secondary' size='small' sx={{ width: '100%' }}>
                        <Typography color={theme.palette.grey['50']}>
                          Modify Invoice
                        </Typography>
                      </Button>
                    </Link>
                  }
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
}