import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Appbar from '../../../components/Appbar';
import Container from '@mui/material/Container';
import TextSection from '../../../components/TextSection';
import Footer from '../../../components/Footer';


export default function LandingPage() {
  const theme = useTheme();
  return (
    <Box>
      <Box style={{ height: '80vh' }}>
        <Box
          sx={{
            background: theme.palette.grey['200'],
            backgroundImage: `url(/mbi.avif)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            height: '80vh'
          }}
        >
          <Box
            display="flex"
            flexDirection='column '
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%', width: '100%', background: 'linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.4), rgba(0,0,0,0))' }}
          >
            <img src='/mb.avif' alt='mb' style={{ width: '140px' }} />
            <Typography variant="h2" sx={{ fontWeight: 500 }} color={theme.palette.grey['50']} align='center'>The Macron Boutique</Typography>
          </Box>
        </Box>
        <Appbar white linearGradient />
      </Box>

      <Box sx={{ p: { sm: 2, md: 4 }, background: 'linear-gradient(to bottom, #A12929, #D12F2F)' }}>
        <Container maxWidth='md'>
          <Typography variant="h3" align="center" color={theme.palette.grey['50']} sx={{ py: 4 }}>
            Social Media, Paid Media, Website Optimization And Lead Generation Driving Dessert Delivery Through the Christmas Season
          </Typography>
        </Container>
      </Box>

      <TextSection title="Who is the Macron Boutique?">
        <Typography variant='body1'>
          Indulge in an extraordinary culinary experience at The Macron Boutique, where each bite harmonizes flavour and artistry.
          Situated in the heart of Toronto, The Macron Boutique transcends the boundaries of a traditional patisserie. We are dedicated
          to curating exceptional moments through our meticulous craftsmanship.
        </Typography>
        <Typography variant='body1'>
          Our distinguished collection features a range of handcrafted macarons and pastries, each a testament to our unwavering commitment
          to excellence. Since 2014, The Macron Boutique has graced Toronto with unparalleled culinary creations. Taking immense pride in
          having served esteemed brands such as Nordstrom, Square One in Mississauga, Holt Renfrew, American Airlines, and Alfa Romero.
        </Typography>
      </TextSection>
      <TextSection title="Growth Partner" color={theme.palette.grey['200']}>
        <Typography variant='body1'>
          The Macron Boutique had chosen Growth Partner Plan, tailored to amplify revenue streams across the board. To achieve this,
          Simplex Media Group has meticulously crafted a multi-strategy approach, leveraging our profound expertise in Digital Media, Out
          of Home Advertising, and precision Lead Generation Tactics.
        </Typography>
      </TextSection>
      <TextSection title="Outcomes">
        <Typography variant='body1'>
          The website enhancement for CourierToronto aimed to create an intuitive, user-friendly platform that prioritizes ease of
          navigation and seamless order placement. Through careful design, optimization, and functionality integration, we transformed
          the website into a valuable tool for both the client and their customers.  The result is a highly efficient and visually engaging
          platform that fosters positive user experiences and drives business growth.
        </Typography>
        <TextSection title="Impressive Viewability Rate" nested smallTitle>
          <Typography variant='body1'>
            Achieved an exceptional viewability rate of 90%, indicating that the website's content was effectively
            displayed and engaging for visitors.
          </Typography>
        </TextSection>
        <TextSection title="Reach" nested bottom smallTitle>
          <Typography variant='body1'>
            Successfully garnered a unique reach of 10,000 users, significantly expanding the brand's visibility and exposure within the
            target audience.
          </Typography>
        </TextSection>
        <TextSection title="Google Ads Performance" nested smallTitle>
          <Typography variant='body1'>
            Generated 46,000 clicks through Google Ads, demonstrating a strong resonance with the audience and effective ad content.
            Garnered an impressive 400,000 impressions, showcasing a substantial presence in front of potential customers.
          </Typography>
        </TextSection>
        <TextSection title="Click-Through Rate (CTR)" nested smallTitle>
          <Typography variant='body1'>
            A noteworthy click-through rate of 8.67%, indicating that the ads were highly relevant and compelling to the audience,
            resulting in a higher likelihood of conversions.
          </Typography>
        </TextSection>
        <TextSection title="Substantial Orders Placed" nested smallTitle>
          <Typography variant='body1'>
            Successfully facilitated 1,059 orders placed through the website, reflecting a direct impact on the client's business
            growth and revenue generation.
          </Typography>
        </TextSection>
        <Typography variant='body1'>
          Overall, the campaign's outstanding performance metrics indicate a highly successful website optimization initiative. The combination of improved
          user experience, strategic ad placement, and compelling content led to a substantial increase in user engagement and conversions, ultimately
          contributing to the client's business success.
        </Typography>
      </TextSection>
      <Footer />
    </Box>
  );
}