import useTheme from '@mui/material/styles/useTheme';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Appbar from '../../components/Appbar';
import BlogGrid from '../../components/BlogGrid';
import Footer from '../../components/Footer';


export default function WorkPage() {
  const theme = useTheme();
  return (
    <Box display='flex' flexDirection='column' sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar />
        <Container maxWidth="md">
          <Typography variant="h3" align="center" color={theme.palette.grey['900']} sx={{ py: 4 }}>
            Latest News
          </Typography>
          <Box sx={{ mt: 4, mb: 12 }}>
            <BlogGrid />
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}