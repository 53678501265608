import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import useTheme from '@mui/material/styles/useTheme';
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import CircularProgress from '@mui/material/CircularProgress';

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';


const SERVICE_ID = 'service_nztr3of';
const TEMPLATE_ID = 'template_ri4oza9';
const PUBLIC_KEY = 'T25bB89UHs2eakRK2';

const pages = ['Work', 'Services', 'News', 'Contact'];
const initForm = {
  email: '',
  subscribe: true
};
const initEmailStatus = {
  error: false,
  message: ''
};

const successEmailStatus = {
  error: false,
  message: 'Message sent successfully!'
};

const errorEmailStatus = {
  error: true,
  message: 'Message failed to send. Please contact the email listed directly.'
};

const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
};

export default function Footer() {
  const theme = useTheme();
  const [form, setForm] = React.useState(initForm);
  const [errors, setErrors] = React.useState({});
  const [emailStatus, setEmailStatus] = React.useState(initEmailStatus);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const setValue = (id) => (e) => setForm({ ...form, [id]: e.target.value });

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();

    setErrors({});

    let newErrors = {};
    if (!form.email) newErrors.email = 'Please enter your email address';
    else if (!validateEmail(form.email)) newErrors.email = 'Please enter a valid email address';

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      emailjs.send(SERVICE_ID, TEMPLATE_ID, form, PUBLIC_KEY)
        .then((_) => {
          setForm(initForm);
          setEmailStatus(successEmailStatus);
          setLoading(false);
          setSuccess(true);
        }, (_) => {
          setEmailStatus(errorEmailStatus);
          setLoading(false);
          setSuccess(true);
        });
    } else {
      setEmailStatus(initEmailStatus);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: '100%' }} bgcolor={theme.palette.grey['900']}>
      <Container maxWidth="md" sx={{ py: 12 }}>
        <Typography
          variant="h4"
          noWrap
          align="center"
          href="#app-bar-with-responsive-menu"
          sx={{
            mb: 4,
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: theme.palette.grey['50'],
            textDecoration: 'none',
          }}
        >
          simplex
        </Typography>
        <Box sx={{ flex: 1, justifyContent: 'center', display: { xs: 'inline', sm: 'inline-flex' }, alignContent: 'center', width: '100%' }}>
          {pages.map((page) => (
            <Link key={page} to={`/${page.toLowerCase()}`} style={{ textDecoration: 'none' }}>
              <Button
                sx={{ mx: { xs: 'auto', sm: 4 }, my: { xs: 2, sm: 0 }, color: 'black', display: 'block', textTransform: 'none' }}
              >
                <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                  {page}
                </Typography>
              </Button>
            </Link>
          ))}
        </Box>
        <Container maxWidth="sm" sx={{ my: 4, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
          <Box display='flex' alignItems='center' flexDirection='row'>
            <Link to={`/dashboard`} style={{ textDecoration: 'none' }}>
              <Typography variant="body1" align='center' color={theme.palette.grey['50']}>
                Client Dashboard
              </Typography>
            </Link>
            <Link to={`/dashboard`} style={{ textDecoration: 'none' }}>
              <Typography variant="body1" align='center' color={theme.palette.grey['50']}>
                <ArrowOutwardIcon sx={{ ml: 0.5, height: '18px' }} />
              </Typography>
            </Link>
          </Box>
        </Container>
        <Container maxWidth="sm" sx={{ my: 10 }}>
          <Typography variant="h5" align='center' color={theme.palette.grey['50']} sx={{ mb: 2 }}>
            Stay in Touch
          </Typography>
          <Typography variant="body2" align='center' color={theme.palette.grey['50']}>
            Sign up to be kept up to date on policy changes, package changes, new services, and more.
            We respect your privacy and will never share your information with any third-party vendors.
          </Typography>
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <TextField
                color='info'
                sx={{
                  '& .MuiInputLabel-root': {
                    color: theme.palette.grey['400']
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: theme.palette.grey['500'],
                    },
                    '&:hover fieldset': {
                      borderColor: theme.palette.grey['50'],
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme.palette.grey['50'],
                    },
                  },
                  '& .MuiInputBase-input': {
                    color: theme.palette.grey['50'],
                  },
                  '& .MuiOutlinedInput-input::placeholder': {
                    color: theme.palette.grey['50']
                  },
                  "& label": {
                    "&.Mui-focused": {
                      color: theme.palette.grey['50']
                    }
                  }
                }}
                fullWidth
                label="Email Address"
                variant="outlined"
                value={form.email}
                onChange={setValue('email')}
                error={Boolean(errors.email)}
              />
              {errors.email &&
                <Typography variant='body1' align='left' color={theme.palette.secondary.main} >
                  {errors.email}
                </Typography>
              }
              {emailStatus.message &&
                <Typography variant='body2' align='center' sx={{ my: 1, color: emailStatus.error ? theme.palette.secondary.main : theme.palette.success.main }} >
                  {emailStatus.message}
                </Typography>
              }
            </Grid>

            <Grid item alignItems="stretch" style={{ display: "flex" }} xs={3}>
              <Button color="secondary" variant="contained" sx={{ textTransform: 'none' }} onClick={handleSubmit} disabled={loading || success}>
                {loading && <CircularProgress sx={{ color: '#fff' }} />}
                {success &&
                  <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                    Message sent!
                  </Typography>
                }
                {!success && !loading &&
                  <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                    Subscribe
                  </Typography>
                }
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Typography variant="body2" align="center" color={theme.palette.grey['50']} sx={{ mt: 4 }}>
          &copy; {new Date().getFullYear()} Simplex Media Group
        </Typography>
      </Container >
    </Box >
  );
}
