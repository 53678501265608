import useTheme from '@mui/material/styles/useTheme';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Appbar from '../Appbar';
import Footer from '../../../components/Footer';

export default function DashboardRequestHome({ user, setUser }) {
  const theme = useTheme();

  return (
    <Box display='flex' flexDirection='column' sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar setUser={setUser} />
        <Container maxWidth="md" sx={{ minHeight: '100vh' }}>
          <Typography variant="h3" align="left" color={theme.palette.grey['900']} sx={{ py: 2 }}>
            {user.companyName}
          </Typography>
          <Typography variant="body1" align="left" color={theme.palette.grey['900']} sx={{ py: 2 }}>
            Thank you for signing up. We are currently reviewing your account creation request. We will
            contact you shortly with next steps.
          </Typography>


          <Typography variant="body1" align="left" color={theme.palette.grey['900']} sx={{ py: 2 }}>
            If you already had access to your account and are seeing this page, please contact support.
          </Typography>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}