import React from 'react';
import useTheme from '@mui/material/styles/useTheme';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';


import CardContent from '@mui/material/CardContent';
import Appbar from '../../components/Appbar';
import Footer from '../../components/Footer';

const initForm = {
  email: '',
  password: ''
};

const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
};

export default function DashboardPage({ setUser }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(initForm);
  const [errors, setErrors] = React.useState({});
  const [signInError, setSignInError] = React.useState('');
  const theme = useTheme();

  const setValue = (id) => (e) => setForm({ ...form, [id]: e.target.value });

  const handleSignIn = (e) => {
    setLoading(true);
    e.preventDefault();

    setSignInError('');
    setErrors({});

    let newErrors = {};
    if (!form.email) newErrors.email = 'Please enter your email address';
    else if (!validateEmail(form.email)) newErrors.email = 'Please enter a valid email address';

    if (!form.password) newErrors.password = 'Please enter your password';

    setErrors(newErrors);

    if (Object.keys(newErrors).length !== 0) {
      setLoading(false);
      return;
    }

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(form)
    };

    fetch('https://wd-simplex-media-group-server.fly.dev/user/signin', requestOptions)
      .then(async (res) => {
        return { status: res.status, json: await res.json() };
      })
      .then((data) => {
        if (data.status !== 200 || data.json.message !== "OK") {
          setSignInError(data.json.message);
        } else {
          setUser(data.json.user);
          if (data.json.user.type === 'REQUEST') {
            navigate('/dashboard/request');
          } else if (data.json.user.type === 'CLIENT') {
            navigate('/dashboard/home');
          } else if (data.json.user.type === 'ADMIN') {
            navigate('/dashboard/admin');
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setSignInError('Failed to sign in. Please try again later.');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Box display='flex' flexDirection='column' sx={{ background: theme.palette.grey['50'], minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar />
        <Container maxWidth="sm" sx={{ p: 4 }}>
          <Typography variant="h3" align="center" color={theme.palette.grey['900']}>
            Client Dashboard
          </Typography>
          <Card variant='outlined' sx={{background: theme.palette.grey['50'], my: 8 }}>
            <CardHeader title="Sign In" />
            <Typography variant="body2" align="center" style={{ fontWeight: '450', color: '#d12f2f' }}>{signInError}</Typography>
            <CardContent>
              <Box
                component="form"
                noValidate
                autoComplete="off"
              >
                <Stack spacing={3}>
                  <TextField
                    required
                    label="Email"
                    size='small'
                    color='info'
                    onChange={setValue('email')}
                    value={form.email}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                  />
                  <TextField
                    required
                    label="Password"
                    color='info'
                    size='small'
                    onChange={setValue('password')}
                    value={form.password}
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {!showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <Button color="secondary" variant="contained" sx={{ textTransform: 'none', height: '45px' }} onClick={handleSignIn} disabled={loading}>
                    {!loading &&
                      <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                        Sign In
                      </Typography>
                    }
                    {loading && <CircularProgress sx={{ color: '#fff' }} />}
                  </Button>
                </Stack>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box justifyContent='center'>
                <Button color="info" variant="contained" sx={{ width: '100%', mb: 2, textTransform: 'none', height: '45px' }} onClick={() => navigate('/dashboard/create_account')}>
                  Create Account
                </Button>
                <Button color="info" variant="outlined" sx={{ width: '100%', mb: 2, textTransform: 'none', height: '45px' }} onClick={() => navigate('/dashboard/contact')}>
                  Recover Account
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}