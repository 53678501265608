import useTheme from '@mui/material/styles/useTheme';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

import Appbar from '../Appbar';
import Footer from '../../../components/Footer';
import InvoiceLine from '../../../components/Dashboard/InvoiceLine';

export default function DashboardClientHome({ user, setUser }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const isAdmin = user.type === 'ADMIN';
  const client = location.state?.client ?? user;
  const statusColor = client.type === 'ADMIN'
    ? theme.palette.grey['900']
    : (client.type === 'CLIENT'
      ? theme.palette.success.main
      : theme.palette.secondary.main
    );
  const isClientAdmin = client.type === 'ADMIN';

  const [forceRefresh, setForceRefresh] = React.useState(0);
  const [invoices, setInvoices] = React.useState(null);
  const [prevInvoices, setPrevInvoices] = React.useState(null);
  const [fetchError, setFetchError] = React.useState('');


  const [customerId, setCustomerId] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState('');
  const [error, setError] = React.useState('');
  const [disableAccept, setDisableAccept] = React.useState(client.customer !== 'TBD')

  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clientId: client._id })
  };

  React.useEffect(() => {
    setPrevInvoices(null);
    setInvoices(null);
    fetch('https://wd-simplex-media-group-server.fly.dev/user/invoices', requestOptions)
      .then(async (res) => {
        return { status: res.status, json: await res.json() };
      })
      .then((data) => {
        if (data.status !== 200 || data.json.message !== "OK") {
          setFetchError(data.json.message);
        } else {
          setPrevInvoices(data.json.closedInvoices);
          setInvoices(data.json.openInvoices);
        }
      })
      .catch((err) => {
        console.log(err);
        setFetchError('Failed to fetch invoices.');
      });
  }, [forceRefresh]);

  const handleCustomerId = (e) => {
    setLoading(true);
    e.preventDefault();

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: user._id, clientId: client._id, customerId })
    };

    fetch('https://wd-simplex-media-group-server.fly.dev/user/customerId', requestOptions)
      .then(async (res) => {
        return { status: res.status, json: await res.json() };
      })
      .then((data) => {
        if (data.status !== 200) {
          setError(data.json.message);
          setSuccess('');
        } else {
          setSuccess(`Stripe ID set successfully.`);
          setError('');
          setDisableAccept(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setError('Failed set stripe ID');
        setSuccess('');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box display='flex' flexDirection='column' sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar user={user} setUser={setUser} />
        <Container maxWidth="md" sx={{ minHeight: '100vh' }}>
          {!isAdmin &&
            <Typography variant="body1" color={theme.palette.grey['900']} fontSize={35} fontWeight={100} mr={2} mb={4}>
              Welcome, {client.fullName}
            </Typography>
          }
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center' justifyContent='left'>
              <Typography variant="h3" color={theme.palette.grey['900']} mr={2}>
                {client.companyName}
              </Typography>
              <Typography variant="h6" fontWeight={400} color={statusColor}>
                {client.type}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              {isAdmin &&
                <Link to={'https://dashboard.stripe.com/invoices'} target="_blank" style={{ textDecoration: 'none' }}>
                  <Button color="secondary" variant="contained" size='small' sx={{ mr: 0.5 }}>
                    NEW INVOICE
                  </Button>
                </Link>
              }
              <Button color="info" variant="outlined" size='small' onClick={() => setForceRefresh(old => old + 1)} disabled={!invoices}>
                REFRESH
              </Button>
            </Box>
          </Box>

          <Typography variant="body1" my={2} align='center' color={theme.palette.grey['900']}>
            {fetchError}
          </Typography>

          {!isClientAdmin &&
            <>
              <Typography variant="h6" alignItems='center' color={theme.palette.grey['900']} mt={3} mb={1}>Outstanding Invoices</Typography>
              {!invoices &&
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  my={8}
                >
                  <CircularProgress sx={{ color: '#d12f2f' }} />
                </Box>
              }
              {invoices && invoices.map(invoice => <InvoiceLine key={invoice.id} invoice={invoice} outstanding={true} isAdmin={isAdmin} />)}
              {invoices && invoices.length === 0 && (
                <Typography variant="body1" my={2} align='center' color={theme.palette.grey['900']}>
                  All invoices resolved!
                </Typography>
              )}

              <Typography variant="h6" alignItems='center' color={theme.palette.grey['900']} mt={3} mb={1}>Previous Invoices</Typography>
              {!prevInvoices &&
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  my={8}
                >
                  <CircularProgress sx={{ color: '#d12f2f' }} />
                </Box>
              }
              {prevInvoices && prevInvoices.map(invoice => <InvoiceLine key={invoice.id} invoice={invoice} />)}
              {prevInvoices && prevInvoices.length === 0 && (
                <Typography variant="body1" my={2} align='center' color={theme.palette.grey['900']}>
                  No invoices found ...
                </Typography>
              )}
            </>
          }

          <Typography variant="h6" mt={3} alignItems='center' color={theme.palette.grey['900']} >Account Details </Typography>
          <Card variant='outlined' sx={{ background: theme.palette.grey['100'] }}>
            <CardContent>
              <Stack>
                {isAdmin &&
                  <>
                    <Link to={`https://dashboard.stripe.com/customers/${client.customerId}`} target="_blank" style={{ textDecoration: 'none', width: '100%' }}>
                      <Box display='flex' alignItems='center'>
                        <Typography variant="body1" color={theme.palette.secondary.main}>
                          Stripe Profile
                        </Typography>
                        <ArrowOutwardIcon color='secondary' fontSize='small' />
                      </Box>
                    </Link>
                    <Typography variant="body1" align="left" color={theme.palette.grey['900']}>
                      <b>Stripe ID</b>: {!matches && <br />}{client.customerId}
                    </Typography>
                  </>
                }
                <Typography variant="body1" align="left" color={theme.palette.grey['900']} >
                  <b>Full Name</b>: {!matches && <br />}{client.fullName}
                </Typography>
                <Typography variant="body1" align="left" color={theme.palette.grey['900']} >
                  <b>Email</b>: {!matches && <br />}{client.email}
                </Typography>
                {isAdmin && client.type === "REQUEST" && disableAccept &&
                  <>
                    <Box display='flex' alignItems='center' justifyContent='center' sx={{ width: '100%' }}>
                      <Box flexGrow={1}>
                        <TextField
                          required
                          sx={{ my: 2, width: '100%' }}
                          label="Stripe Customer ID"
                          size='small'
                          color='info'
                          onChange={e => setCustomerId(e.target.value)}
                          value={customerId}
                          error={error}
                          helperText={error}
                        />
                      </Box>
                      <Button color="success" variant="contained" sx={{ ml: 2, textTransform: 'none', color: '#FFF' }} onClick={handleCustomerId} disabled={loading}>
                        {!loading &&
                          <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                            Set Stripe ID
                          </Typography>
                        }
                        {loading && <CircularProgress sx={{ color: '#fff' }} />}
                      </Button>
                    </Box>
                    <Typography variant="body1" align="center" sx={{ fontWeight: '450', color: '#d12f2f' }}>{error}</Typography>
                    <Typography variant="body1" align="center" sx={{ fontWeight: '300' }}>{success}</Typography>
                  </>
                }
                <Box>
                  <Box my={2} />
                  <Divider />
                  <Box my={2} />
                  {isAdmin &&
                    <Box display='flex' alignItems='center' justifyContent='center'>
                      {client.type === 'REQUEST' &&
                        <Button disabled={disableAccept} color="success" variant="contained" sx={{ textTransform: 'none', color: '#FFF' }} onClick={() => navigate('/dashboard/change_type', { state: { client: client, newType: 'CLIENT' } })}>
                          Accept Client Request
                        </Button>
                      }
                      {client.type === 'CLIENT' &&
                        <Button color="secondary" variant="outlined" sx={{ textTransform: 'none' }} onClick={() => navigate('/dashboard/reset_password', { state: { client: client } })}>
                          Reset Password
                        </Button>
                      }
                      {client.type === 'REFUSED' &&
                        <Button color="success" variant="contained" sx={{ textTransform: 'none', color: '#FFF' }} onClick={() => navigate('/dashboard/change_type', { state: { client: client, newType: 'REQUEST' } })}>
                          Reinstate Account
                        </Button>
                      }
                      <Button color="secondary" variant="contained" sx={{ ml: 2, textTransform: 'none' }} onClick={() => navigate('/dashboard/change_type', { state: { client: client, newType: 'REFUSED' } })} disabled={client.type === 'REFUSED' || client.type === 'ADMIN'}>
                        Block Access
                      </Button>
                    </Box>
                  }
                  {!isAdmin &&
                    <Box display='flex' alignItems='center' justifyContent='center'>
                      <Button color="secondary" variant="outlined" sx={{ textTransform: 'none' }} onClick={() => navigate('/dashboard/reset_password', { state: { client: client } })}>
                        Change my password
                      </Button>
                    </Box>
                  }
                </Box>
              </Stack>
            </CardContent>
          </Card>
          <Box my={4} />
        </Container>
      </Box >
      <Footer />
    </Box >
  );
}