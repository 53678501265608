import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Appbar from '../../../components/Appbar';
import Container from '@mui/material/Container';
import TextSection from '../../../components/TextSection';
import Footer from '../../../components/Footer';


export default function LandingPage() {
  const theme = useTheme();
  return (
    <Box>
      <Box sx={{
        height: { xs: '60vh', sm: '60vh', md: '80vh' }
      }}>
        <Box
          sx={{
            background: theme.palette.grey['200'],
            backgroundImage: `url(/vwi.jpg)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            height: { xs: '60vh', sm: '60vh', md: '80vh' }
          }}
        >
          <Box
            display="flex"
            flexDirection='column '
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%', width: '100%', background: 'linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.4), rgba(0,0,0,0))' }}
          >
            <img src='/vw.png' alt='mb' style={{ width: '160px' }} />
            <Typography variant="h2" sx={{ fontWeight: 500 }} color={theme.palette.grey['50']} align='center'>VW Express</Typography>
          </Box>
        </Box>
        <Appbar white linearGradient />
      </Box>

      <Box sx={{ p: { sm: 2, md: 4 }, background: 'linear-gradient(to bottom, #A12929, #D12F2F)' }}>
        <Container maxWidth='md'>
          <Typography variant="h3" align="center" color={theme.palette.grey['50']} sx={{ py: 4 }}>
            Paid Media and Website Optimization Driving Awareness For VW Express Services
          </Typography>
        </Container>
      </Box>

      <TextSection title="Who is VW Express?">
        <Typography variant='body1'>
          VW Express (VW) is a Toronto-based courier company with a successful track record spanning 25 years.
          Having completed over 100 thousand deliveries across the Greater Toronto Area (GTA) and its surrounding areas,
          VW Express remains steadfast in its ambition for continued growth. The company boasts an impressive clientele,
          including esteemed names such as Sporting Life, RN Design, and Nike Canada.
        </Typography>
      </TextSection>
      <TextSection title="Growth Partner" color={theme.palette.grey['200']}>
        <Typography variant='body1'>
          VW Express opted for the Growth Partner Plan to achieve their short-term and long-term objectives.
          Simplex Media Group crafted a comprehensive multi-strategy campaign, capitalizing on our expertise in Google Search,
          Google Display, and YouTube. The outcome was a notable increase in brand relevance for VW Express, resulting in a
          substantial 75% influx of new customers to their landing page.
        </Typography>
      </TextSection>
      <TextSection title="Objectives">
        <Typography variant='body1'>
          The primary focus is to raise brand awareness, and channel paid media efforts towards the website. This strategic
          shift aims to optimize the user experience, driving more deliveries. By directing resources towards these critical areas,
          the team anticipated a significant boost in brand visibility and operational efficiency, ultimately leading to
          enhanced customer acquisition and business growth.
        </Typography>
      </TextSection>
      <TextSection title="Digital Strategy" color={theme.palette.grey['200']}>
        <TextSection title="Paid Media" nested top color={theme.palette.grey['200']}>
          <Typography variant='body1'>
            Integrating Paid Media with precise audience targeting proved to be a pivotal factor in transforming the client's campaign.
            This approach not only enabled us to tailor our advertisements to the specific interests and demographics of the target
            audience, but its level of accuracy surpassed all expectations. The inclusion of Paid Media emerged as a valuable addition
            to the campaign strategy, playing a significant role in attaining the desired outcomes. By leveraging this tool effectively,
            we optimized visibility and engagement, ultimately driving impressive results for the client.
          </Typography>
        </TextSection>
        <TextSection title="Website Optimization" nested bottom color={theme.palette.grey['200']}>
          <Typography variant='body1'>
            The website enhancement for CourierToronto aimed to create an intuitive, user-friendly platform that prioritizes ease of
            navigation and seamless order placement. Through careful design, optimization, and functionality integration, we transformed
            the website into a valuable tool for both the client and their customers.  The result is a highly efficient and visually engaging
            platform that fosters positive user experiences and drives business growth.
          </Typography>
        </TextSection>
      </TextSection>

      <TextSection title="Outcomes">
        <Typography variant='body1'>
          The website enhancement for CourierToronto aimed to create an intuitive, user-friendly platform that prioritizes ease of
          navigation and seamless order placement. Through careful design, optimization, and functionality integration, we transformed
          the website into a valuable tool for both the client and their customers.  The result is a highly efficient and visually engaging
          platform that fosters positive user experiences and drives business growth.
        </Typography>
        <TextSection title="Impressive Viewability Rate" nested smallTitle>
          <Typography variant='body1'>
            Achieved an exceptional viewability rate of 90%, indicating that the website's content was effectively
            displayed and engaging for visitors.
          </Typography>
        </TextSection>
        <TextSection title="Reach" nested bottom smallTitle>
          <Typography variant='body1'>
            Successfully garnered a unique reach of 10,000 users, significantly expanding the brand's visibility and exposure within the
            target audience.
          </Typography>
        </TextSection>
        <TextSection title="Google Ads Performance" nested smallTitle>
          <Typography variant='body1'>
            Generated 46,000 clicks through Google Ads, demonstrating a strong resonance with the audience and effective ad content.
            Garnered an impressive 400,000 impressions, showcasing a substantial presence in front of potential customers.
          </Typography>
        </TextSection>
        <TextSection title="Click-Through Rate (CTR)" nested smallTitle>
          <Typography variant='body1'>
            A noteworthy click-through rate of 8.67%, indicating that the ads were highly relevant and compelling to the audience,
            resulting in a higher likelihood of conversions.
          </Typography>
        </TextSection>
        <TextSection title="Substantial Orders Placed" nested smallTitle>
          <Typography variant='body1'>
            Successfully facilitated 1,059 orders placed through the website, reflecting a direct impact on the client's business
            growth and revenue generation.
          </Typography>
        </TextSection>
        <Typography variant='body1'>
          Overall, the campaign's outstanding performance metrics indicate a highly successful website optimization initiative. The combination of improved
          user experience, strategic ad placement, and compelling content led to a substantial increase in user engagement and conversions, ultimately
          contributing to the client's business success.
        </Typography>
      </TextSection>
      <Footer />
    </Box>
  );
}