import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import useTheme from '@mui/material/styles/useTheme';
import { Link } from "react-router-dom";

import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

export default function DashboardButton() {
  const theme = useTheme();
  return (
    <Box position="absolute" bottom={20} right={20}>

      <Link to={`/dashboard`} style={{ textDecoration: 'none' }}>
        <Fab
          variant="extended"
          size="medium"
          color="primary"
          sx={{
            color: theme.palette.grey['900'],
            backgroundColor: theme.palette.grey['100'],
            '&:hover': {
              backgroundColor: theme.palette.grey['500']
            }
          }}>
          Client Dashboard
          <ArrowOutwardIcon sx={{ ml: 1 }} />
        </Fab>
      </Link>
    </Box>
  );
};