import useTheme from '@mui/material/styles/useTheme';
import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InvoiceLine from '../../../components/Dashboard/InvoiceLine';

import Appbar from '../Appbar';
import Footer from '../../../components/Footer';

const initFilters = {
  admin: true, client: true, request: true, blocked: false
};

const nullFilters = {
  admin: false, client: false, request: false, blocked: false
};

export default function DashboardAdminHome({ user, setUser }) {
  const [users, setUsers] = React.useState(null);
  const [invoices, setInvoices] = React.useState(null);
  const [forceRefresh, setForceRefresh] = React.useState(0);
  const [filter, setFilter] = React.useState(initFilters);
  const [fetchErrors, setFetchErrors] = React.useState({ users: '', invoices: '' });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();

  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ userId: user._id })
  };

  const requestOptionsInvoice = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ clientId: user._id })
  };

  React.useEffect(() => {
    setUsers(null);
    fetch('https://wd-simplex-media-group-server.fly.dev/user', requestOptions)
      .then(async (res) => {
        return { status: res.status, json: await res.json() };
      })
      .then((data) => {
        if (data.status !== 200 || data.json.message !== "OK") {
          setFetchErrors({ ...fetchErrors, users: data.json.message });
        } else {
          setUsers(data.json.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setFetchErrors({ ...fetchErrors, users: 'Failed to fetch users.' });
      })
  }, [forceRefresh, filter.admin, filter.client, filter.request, filter.blocked]);

  React.useEffect(() => {
    setInvoices(null);
    fetch('https://wd-simplex-media-group-server.fly.dev/user/invoices', requestOptionsInvoice)
      .then(async (res) => {
        return { status: res.status, json: await res.json() };
      })
      .then((data) => {
        if (data.status !== 200 || data.json.message !== "OK") {
          setFetchErrors({ ...fetchErrors, invoices: data.json.message });
        } else {
          setInvoices(data.json.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setFetchErrors({ ...fetchErrors, invoices: 'Failed to fetch invoices.' });
      });
  }, [forceRefresh]);

  return (
    <Box display='flex' flexDirection='column' sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar user={user} setUser={setUser} />
        <Container maxWidth="md" sx={{ minHeight: '100vh' }}>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Typography variant="h3" align="left" color={theme.palette.grey['900']} sx={{ py: 2 }}>
              Admin Panel
            </Typography>
            <Button color="info" variant="outlined" size='small' onClick={() => setForceRefresh(old => old + 1)} disabled={!users || !invoices}>
              REFRESH
            </Button>
          </Box>
          <Typography variant="body1" align="center" color={theme.palette.secondary.main}>
            {fetchErrors.users}
          </Typography>
          <Typography variant="body1" align="center" fontWeight='bold' color={theme.palette.grey['900']}>
            User Filters
          </Typography>
          <Box display={matches ? 'flex' : 'block'} justifyContent='center' alignItems='center'>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <FormControlLabel
                control={
                  <Checkbox
                    color='secondary'
                    checked={filter.admin}
                    onChange={(e) => setFilter({ ...filter, admin: e.target.checked })}
                    disabled={!users}
                  />
                }
                label="Admins"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color='secondary'
                    checked={filter.client}
                    onChange={(e) => setFilter({ ...filter, client: e.target.checked })}
                    disabled={!users}
                  />
                }
                label="Clients"
              />
            </Box>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <FormControlLabel
                control={
                  <Checkbox
                    color='secondary'
                    checked={filter.request}
                    onChange={(e) => setFilter({ ...filter, request: e.target.checked })}
                    disabled={!users}
                  />
                }
                label="Requests"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color='secondary'
                    checked={filter.blocked}
                    onChange={(e) => setFilter({ ...filter, blocked: e.target.checked })}
                    disabled={!users}
                  />
                }
                label="Refused"
              />
            </Box>
          </Box>
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Button color="secondary" variant="outline" size='sm' sx={{ textTransform: 'none' }} disabled={!users} onClick={() => setFilter(initFilters)}>
              Select All
            </Button>
            <Button color="secondary" variant="outline" sx={{ textTransform: 'none' }} disabled={!users} onClick={() => setFilter(nullFilters)}>
              Clear All
            </Button>
          </Box>
          <Typography variant="h5" color={theme.palette.grey['900']}>
            Users
          </Typography>
          {!users &&
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              my={8}
            >
              <CircularProgress sx={{ color: '#d12f2f' }} />
            </Box>
          }
          {users && users.length === 0 && (
            <Typography variant="body1" my={2} align='center' color={theme.palette.grey['900']}>
              No Users Found...
            </Typography>
          )}
          {users && users.map((client) => {
            if (client.type === 'ADMIN' && !filter.admin) return null;
            if (client.type === 'CLIENT' && !filter.client) return null;
            if (client.type === 'REQUEST' && !filter.request) return null;
            if (client.type === 'REFUSED' && !filter.blocked) return null;

            const color =
              client.type === 'ADMIN'
                ? theme.palette.grey['900']
                : (client.type === 'CLIENT'
                  ? theme.palette.success.main
                  : theme.palette.secondary.main
                );

            return (
              <Card variant='outlined' key={client?._id} onClick={() => navigate('/dashboard/home', { state: { client: client } })}
                sx={{
                  my: 2,
                  '&:hover': {
                    cursor: 'pointer',
                    backgroundColor: theme.palette.grey['100']
                  }
                }}
              >
                <CardContent>
                  <Box display='flex'>
                    <Box flexGrow={1}>
                      <Typography variant='body1' fontSize={20} fontWeight={500}>{client?.companyName}</Typography>
                      <Typography variant='body1' fontWeight={200} >{client?.fullName}</Typography>
                    </Box>
                    <Box display="flex" alignItems='center'>
                      <Typography variant='body2' fontSize={12} fontWeight={800} color={color}>{client.type}</Typography>
                      <ChevronRightIcon />
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            );
          })}

          <Box my={4} />
          <Typography variant="body1" align="center" color={theme.palette.secondary.main}>
            {fetchErrors.invoices}
          </Typography>
          <Typography variant="h5" color={theme.palette.grey['900']}>
            Recently Created Invoices
          </Typography>
          {!invoices &&
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              my={8}
            >
              <CircularProgress sx={{ color: '#d12f2f' }} />
            </Box>
          }
          {invoices && invoices.length === 0 && (
            <Typography variant="body1" my={2} align='center' color={theme.palette.grey['900']}>
              No Invoices Found...
            </Typography>
          )}
          {invoices && invoices.map(invoice => <InvoiceLine key={invoice.id} invoice={invoice} adminPage isAdmin />)}

          <Box my={8} />
        </Container>
      </Box>
      <Footer />
    </Box >
  );
}