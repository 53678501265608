import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import React from 'react';

import DashboardChangeAccountType from './pages/Dashboard/ChangeAccountType';
import DashboardResetPassword from './pages/Dashboard/ResetPassword';
import DashboardAdminHome from './pages/Dashboard/HomePage/Admin';
import DashboardClientHome from './pages/Dashboard/HomePage/Client';
import DashboardPage from "./pages/Dashboard";
import DashboardAccountCreationPage from './pages/Dashboard/AccountCreation';
import DashboardContactPage from "./pages/Dashboard/Contact";
import DashboardHelpPage from './pages/Dashboard/Help';
import DashboardRequestHome from './pages/Dashboard/HomePage/Request';
import LandingPage from './pages/Landing';
import WorkPage from './pages/Work';
import VWExpressPage from './pages/Work/VWExpress';
import MacronBoutique from './pages/Work/MacronBoutique';
import ServicesPage from './pages/Services';
import NewsPage from './pages/News';
import ContactPage from './pages/Contact';
import BlogOne from './pages/News/Blog/Post1';
import BlogTwo from './pages/News/Blog/Post2';

import ScrollToTop from './hooks/useScrollToTop';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(55, 255, 255, 0)',
    },
    secondary: {
      main: '#D12F2F',
      light: '#e38734'
    },
    info: {
      main: '#151515',
      light: '#e38734'
    },
    success: {
      main: '#66bb6a'
    },
    background: {
      secondary: '#151515'
    }
  },
  typography: {
    fontFamily: 'Roboto',
    fontSize: 15
  }
});


theme.typography.h3 = {
  fontSize: '2rem',
  fontWeight: 400,
  '@media (min-width:600px)': {
    fontSize: '2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.2rem',
  },
};

function App() {
  const [user, setUser] = React.useState(null);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/work" element={<WorkPage />} />
          <Route path="/work/vw_express" element={<VWExpressPage />} />
          <Route path="/work/the_macron_boutique" element={<MacronBoutique />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/blog/1" element={<BlogOne />} />
          <Route path="/news/blog/2" element={<BlogTwo />} />
          <Route path="/contact" element={<ContactPage />} />

          <Route path='/dashboard' element={<DashboardPage setUser={setUser} />} />
          <Route path='/dashboard/contact' element={<DashboardContactPage />} />
          <Route path='/dashboard/create_account' element={<DashboardAccountCreationPage />} />
          <Route path='/dashboard/help' element={<DashboardHelpPage />} />

          {user &&
            <>
              <Route path='/dashboard/admin' element={<DashboardAdminHome setUser={setUser} user={user} />} />
              <Route path='/dashboard/home' element={<DashboardClientHome user={user} setUser={setUser} />} />
              <Route path='/dashboard/request' element={<DashboardRequestHome user={user} setUser={setUser} />} />
              <Route path='/dashboard/reset_password' element={<DashboardResetPassword user={user} setUser={setUser} />} />
              <Route path='/dashboard/change_type' element={<DashboardChangeAccountType user={user} setUser={setUser} />} />
            </>
          }

          <Route path='/dashboard/*' element={<LandingPage />} />
          <Route path="*" element={<p>Error</p>} />
        </Routes>
      </Router>
    </ThemeProvider >
  );
}

export default App;
