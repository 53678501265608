import useTheme from '@mui/material/styles/useTheme';
import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import Appbar from '../../../components/Appbar';
import Footer from '../../../components/Footer';


const initForm = {
  name: '',
  companyName: '',
  email: '',
  password: '',
  passwordTwice: ''
};

const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
};

export default function DashboardAccountCreationPage() {
  const navigate = useNavigate();
  const [form, setForm] = React.useState(initForm);
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState('');
  const [signUpError, setSignUpError] = React.useState('');


  const setValue = (id) => (e) => setForm({ ...form, [id]: e.target.value });

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();

    setErrors({});

    let newErrors = {};
    if (!form.name) newErrors.name = 'Please enter your name';

    if (!form.companyName) newErrors.companyName = 'Please enter your company name';

    if (!form.email) newErrors.email = 'Please enter your email address';
    else if (!validateEmail(form.email)) newErrors.email = 'Please enter a valid email address';

    if (!form.password) newErrors.password = 'Please enter your password';
    if (!form.passwordTwice) newErrors.passwordTwice = 'Please re-enter your password';

    if (form.password.length < 6) newErrors.password = 'Password must be at least 6 characters';
    else if (form.password && form.passwordTwice && form.password !== form.passwordTwice) newErrors.passwordTwice = 'Passwords do not match!';

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(form)
      };

      fetch('https://wd-simplex-media-group-server.fly.dev/user/signup', requestOptions)
        .then(async (res) => {
          return { status: res.status, json: await res.json() };
        })
        .then((data) => {
          if (data.status !== 200 || data.json.message !== "OK") {
            setSignUpError(data.json.message);
            setSuccess('');
          } else {
            setSuccess('Account successfully created. Please return to the login page.');
            setSignUpError('');
          }
        })
        .catch((err) => {
          console.error(err);
          setSignUpError('Failed to sign up. Please try again later.');
          setSuccess('');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const theme = useTheme();
  return (
    <Box display='flex' flexDirection='column' sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar />
        <Container maxWidth="md" >
          <Typography variant="h3" align="center" color={theme.palette.grey['900']} sx={{ py: 4 }}>
            Dashboard Account Creation
          </Typography>
          <Typography variant="body1" align="center" sx={{ mb: 2, fontWeight: '450', color: '#d12f2f' }}>{signUpError}</Typography>

          <Typography variant="h6" align="center" sx={{ mb: 4, fontWeight: '300' }}>{success}</Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ mb: 8 }}
          >
            <Stack spacing={3}>
              <TextField
                required
                size='small'
                label="Full Name"
                color='info'
                onChange={setValue('name')}
                value={form.name}
                error={errors.name}
                helperText={errors.name}
              />
              <TextField
                required
                size='small'
                label="Company Name"
                color='info'
                onChange={setValue('companyName')}
                value={form.companyName}
                error={errors.companyName}
                helperText={errors.companyName}
              />
              <TextField
                required
                label="Email"
                size='small'
                color='info'
                onChange={setValue('email')}
                value={form.email}
                error={errors.email}
                helperText={errors.email}
              />
              <TextField
                required
                label="Password"
                type='password'
                size='small'
                color='info'
                onChange={setValue('password')}
                value={form.password}
                error={errors.password}
                helperText={errors.password}
              />
              <TextField
                required
                label="Retype your password"
                type='password'
                size='small'
                color='info'
                onChange={setValue('passwordTwice')}
                value={form.passwordTwice}
                error={errors.passwordTwice}
                helperText={errors.passwordTwice}
              />
              <Button color="secondary" variant="contained" sx={{ textTransform: 'none', height: '50px' }} onClick={handleSubmit} disabled={loading || Boolean(success)}>
                {loading && <CircularProgress sx={{ color: '#fff' }} />}
                {!loading &&
                  <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                    Create Account
                  </Typography>
                }
              </Button>

              <Button color="info" variant={success ? "contained" : "outlined"} sx={{ textTransform: 'none', height: '50px' }} onClick={() => navigate('/dashboard')}>
                <Typography variant="body2" align="center">
                  Return to Dashboard Login
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Container>
      </Box >
      <Footer />
    </Box >
  );
}