import useTheme from '@mui/material/styles/useTheme';
import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { IMaskInput } from 'react-imask';
import Typography from '@mui/material/Typography';
import emailjs from '@emailjs/browser';
import { useNavigate } from 'react-router-dom';


import Appbar from '../../../components/Appbar';
import Footer from '../../../components/Footer';

const SERVICE_ID = 'service_nztr3of';
const TEMPLATE_ID = 'template_ri4oza9';
const PUBLIC_KEY = 'T25bB89UHs2eakRK2';

const initForm = {
  name: '',
  companyName: 'N/A',
  contactMethod: 'N/A',
  email: '',
  phone: 'N/A',
  plan: 'Account Recovery',
  details: '',
  subscribe: false
};

const initEmailStatus = {
  error: false,
  message: ''
};

const successEmailStatus = {
  error: false,
  message: 'Message sent successfully!'
};

const errorEmailStatus = {
  error: true,
  message: 'Message failed to send. Please contact the simplexmediagroup@gmail.com directly.'
};

const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
};

const planTypes = ['Account Recovery', 'General Account Help'];

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function DashboardContactPage() {
  const navigate = useNavigate();
  const [form, setForm] = React.useState(initForm);
  const [errors, setErrors] = React.useState({});
  const [emailStatus, setEmailStatus] = React.useState(initEmailStatus);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const setValue = (id) => (e) => setForm({ ...form, [id]: e.target.value });

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();

    setErrors({});

    let newErrors = {};
    if (!form.name) newErrors.name = 'Please enter your name';

    if (!form.details) newErrors.details = 'Please enter your help message';

    if (!form.email) newErrors.email = 'Please enter your email address';
    else if (!validateEmail(form.email)) newErrors.email = 'Please enter a valid email address';

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      emailjs.send(SERVICE_ID, TEMPLATE_ID, form, PUBLIC_KEY)
        .then((_) => {
          setForm(initForm);
          setEmailStatus(successEmailStatus);
          setLoading(false);
          setSuccess(true);
        }, (_) => {
          setEmailStatus(errorEmailStatus);
          setLoading(false);
          setSuccess(true);
        });
    } else {
      setEmailStatus(initEmailStatus);
      setLoading(false);
    }
  };

  const theme = useTheme();
  return (
    <Box display='flex' flexDirection='column' sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar />
        <Container maxWidth="md" >
          <Typography variant="h3" align="center" color={theme.palette.grey['900']} sx={{ py: 4 }}>
            Dashboard Account Assistance
          </Typography>

          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ mb: 8 }}
          >
            <Stack spacing={3}>
              <TextField
                required
                label="Email"
                size='small'
                color='info'
                onChange={setValue('email')}
                value={form.email}
                error={errors.email}
                helperText={errors.email}
              />
              <TextField
                required
                size='small'
                label="Full Name"
                color='info'
                onChange={setValue('name')}
                value={form.name}
                error={errors.name}
                helperText={errors.name}
              />
              <TextField
                required
                size='small'
                label="Account Assistance Required"
                select
                color='info'
                onChange={setValue('plan')}
                defaultValue='Account Recovery'
                value={form.plan}
                error={errors.plan}
                helperText={errors.plan}
              >
                {planTypes.map(method =>
                  <MenuItem key={method} value={method}>
                    {method}
                  </MenuItem>
                )}
              </TextField>
              <TextField
                label="Details"
                required
                multiline
                rows={8}
                color='info'
                onChange={setValue('details')}
                value={form.details}
                error={errors.details}
                helperText={errors.details}
              />
              <Button color="secondary" variant="contained" sx={{ textTransform: 'none', height: '50px' }} onClick={handleSubmit} disabled={loading || success}>
                {loading && <CircularProgress sx={{ color: '#fff' }} />}
                {success &&
                  <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                    Message sent!
                  </Typography>
                }
                {!success && !loading &&
                  <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                    Request Assistance
                  </Typography>
                }
              </Button>

              <Button color="info" variant="outlined" sx={{ textTransform: 'none', height: '50px' }} onClick={() => navigate('/dashboard')}>
                <Typography variant="body2" align="center">
                  Return to Dashboard Login
                </Typography>
              </Button>
            </Stack>
            {emailStatus.message &&
              <Typography variant='h6' align='center' sx={{ my: 8, color: emailStatus.error ? theme.palette.secondary.main : theme.palette.success.main }} >
                {emailStatus.message}
              </Typography>
            }
          </Box>
        </Container>
      </Box >
      <Footer />
    </Box >
  );
}